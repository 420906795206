import * as React from 'react';
import Layout from '../Components/Layout/Layout';
import SEO from '../Components/seo';
// markup
const ShopPage = () => (
  <>
    <SEO title="Success" />
    <Layout page="success">
      <main id="main">
        <div className="container mt-5 mb-5">
          <h1>Form successfully submitted ✅</h1>
          <p>Thank you for reaching out. We will be in touch soon!</p>
        </div>

      </main>
    </Layout>
  </>

);

export default ShopPage;
